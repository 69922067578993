<template>
	<v-container
		v-if="$can([titlePerms + '_list'])"
		id="crud"
		fluid
		tag="section"
	>
		<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title
					>Administración de {{ title }} de {{ $appName }}</v-toolbar-title
				>
			</v-toolbar>

			<v-data-table
				:headers="showHeaders"
				:items="filteredData"
				:search="search"
				sort-by="secret"
				class="elevation-1"
			>
				<template v-slot:[`header.nomenclatura`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`header.detalle`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`header.activo`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`item.clientName`]="{ item }">
					<div
						dark
						class="rounded-pill ml-1 mr-1 text-center"
						:style="$getColorClient(item.clientName)"
					>
						{{ item.clientName }}
					</div>
				</template>

				<template v-slot:[`item.roleName`]="{ item }">
					<div dark>
						{{ item.roleName }}
					</div>
				</template>

				<template v-slot:top>
					<v-toolbar flat color="white" class="pr-4">
						<v-row>
							<v-col sm="6" class="pt-20">
								<v-text-field
									v-model="search"
									append-icon="mdi-magnify"
									:label="'Busqueda de ' + title"
									single-line
									hide-details
								></v-text-field>
							</v-col>
							<v-col sm="2"></v-col>
							<v-col sm="3" class="">
								<v-autocomplete
									v-model="selectedHeaders"
									:items="headers"
									label="Columnas Visibles"
									multiple
									return-object
								>
									<template v-slot:selection="{ item, index }">
										<v-chip v-if="index < 2">
											<span>{{ item.text }}</span>
										</v-chip>
										<span v-if="index === 2" class="grey--text caption"
											>(otras {{ selectedHeaders.length - 2 }}+)</span
										>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col sm="1">
								<v-dialog v-model="dialog" max-width="75%" persistent>
									<template
										v-if="$can([titlePerms + '_create'])"
										v-slot:activator="{ on, attrs }"
									>
										<v-btn
											:color="$cv('principal')"
											dark
											class="mx-2"
											fab
											v-bind="attrs"
											v-on="on"
											><v-icon blue>mdi-plus</v-icon></v-btn
										>
									</template>
									<v-card>
										<v-form ref="form" v-model="valid" lazy-validation>
											<v-toolbar dark :color="$cv('principal')">
												<v-btn icon dark @click="dialog = false">
													<v-icon>mdi-close</v-icon>
												</v-btn>

												<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
												<v-spacer></v-spacer>
												<v-toolbar-items>
													<v-btn
														dark
														text
														@click="$save()"
														v-if="!vista"
														:disabled="!valid"
														>Guardar</v-btn
													>
												</v-toolbar-items>
											</v-toolbar>

											<v-card-text class="card-scrolleable">
												<v-container>
													<v-row>
														<v-col cols="12" md="12" sm="12" class="py-0">
															<v-autocomplete
																:items="clients"
																item-value="id"
																item-text="name"
																label="Producto"
																v-model="editedItem.clientId"
																:rules="[$rulesRequerido]"
																return-object
																@change="getPermisos(editedItem.clientId.id)"
																:disabled="vista"
															></v-autocomplete>
														</v-col>
													</v-row>

													<v-row v-if="$can(['admin_general'])">
														<v-col cols="12" md="12" sm="12">
															<v-autocomplete
																:items="entidades"
																item-value="id"
																item-text="nombre"
																label="Entidad"
																v-model="editedItem.entidadId"
																:rules="[$rulesRequerido]"
																:disabled="vista"
															></v-autocomplete>
														</v-col>
													</v-row>

													<v-row>
														<v-col cols="12" md="12" sm="12" class="py-0">
															<v-text-field
																v-model="editedItem.roleName"
																label="Nombre"
																:disabled="vista"
																:rules="[$rulesRequerido, $rulesMax500]"
															></v-text-field>
														</v-col>
													</v-row>
													<!--
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                v-model="editedItem.description"
                                label="Descripción"
                                :disabled="vista"
                                :rules="[
                                  $rulesRequerido,
                                  $rulesAlfaNum,
                                  $rulesMax500,
                                ]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          -->
													<v-row>
														<v-col cols="12" md="12" sm="12" class="py-0 mt-5">
															<v-autocomplete
																:items="permissionsList"
																v-model="editedItem.privs"
																item-value="id"
																item-text="name"
																chips
																label="Permisos"
																multiple
																return-object
																outlined
																style=""
																:disabled="vista"
															>
															</v-autocomplete>
														</v-col>
													</v-row>
												</v-container>
											</v-card-text>
										</v-form>
									</v-card>
								</v-dialog>
							</v-col>
						</v-row>
					</v-toolbar>
				</template>
				<template v-slot:[`item.privs`]="{ item }">
					<v-chip v-for="(tag, i) in item.privs.slice(0, 4)" :key="i">
						{{ tag.name }}
					</v-chip>

					<v-chip
						v-if="item.privs.length > 4"
						style="background-color: #b0bec5"
					>
						{{ "(otros " + (+item.privs.length - 4) + "+)" }}
					</v-chip>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-row class="">
						<v-col cols="6" md="6" sm="6" style="padding: 6px 0px 0px 0px">
							<v-menu
								bottom
								origin="center center"
								transition="scale-transition"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										color="transparent elevation-0"
										dark
										small
										v-bind="attrs"
										v-on="on"
										min-width="30px"
									>
										<v-icon
											large
											color="blue-grey lighten-3"
											style="font-size: 30px"
										>
											mdi-dialpad
										</v-icon>
									</v-btn>
								</template>

								<v-list>
									<v-list-item
										v-if="$can([titlePerms + '_read'])"
										@click="
											vista = true;
											getRole(item);
										"
									>
										<v-list-item-icon class="mr-0"
											><v-icon
												small
												class="mr-2"
												:color="$cv('btnVista')"
												title="Ver"
											>
												mdi-eye-outline
											</v-icon> </v-list-item-icon
										><v-list-item-content>
											<v-list-item-title>Ver</v-list-item-title>
										</v-list-item-content>
									</v-list-item>

									<v-list-item
										v-if="$can([titlePerms + '_update'])"
										@click="
											vista = false;
											getRole(item);
										"
									>
										<v-list-item-icon class="mr-0"
											><v-icon
												small
												class="mr-2"
												:color="$cv('btnEditar')"
												title="Editar"
											>
												mdi-pencil
											</v-icon> </v-list-item-icon
										><v-list-item-content>
											<v-list-item-title>Editar</v-list-item-title>
										</v-list-item-content>
									</v-list-item>

									<v-list-item
										v-if="$can([titlePerms + '_delete'])"
										@click="deleteItemEntidadRoles(item)"
									>
										<v-list-item-icon class="mr-0">
											<v-icon
												small
												class="mr-2"
												:color="$cv('btnEliminar')"
												title="Eliminar"
											>
												mdi-delete
											</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Eliminar</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			<div v-html="text"></div>

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
	</v-container>
</template>

<script>
	function title() {
		return "Roles";
	}

	export default {
		data: (vm) => ({
			valid: true,
			nowDate: new Date().toISOString().slice(0, 10),
			_method: "PUT",
			autoGrow: true,
			rows: 1,
			title: title(),
			dessertActivo: "",
			route: "roles",
			routeEntidadRoles: "entidades_roles",
			titlePerms: title().toLowerCase(),
			/*routePermissionsByClient: "roles_client",*/
			routePermissionsByRole: "permissions_roles",
			routeEntidadByRole: "entidadByRole",
			menu: false,
			modal: false,
			menu2: false,
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			search: "",
			vista: false,
			users: false,
			headers: [
				{ text: "Acciones", value: "actions", sortable: false, width: "40px" },
				/*{ text: "Id", align: "start", sortable: false, value: "id" },*/
				{
					text: "Producto",
					filterable: true,
					value: "clientName",
					width: "auto",
				},
				{ text: "Rol", filterable: true, value: "roleName", width: "auto" },
				//{ text: "Permisos", filterable: true, value: "privs", width: "50%" },
			],

			desserts: [],
			clients: [],
			entidades: [],
			permissionsList: [],
			editedIndex: -1,
			roleTitle: "",
			editedItem: {
				clientId: "",
				clientName: "",
				roleId: "",
				roleName: "",
				entidadId: "",
				entidadRoleId: "",
				privs: [],
			},
			defaultItem: {
				clientId: "",
				clientName: "",
				roleId: "",
				roleName: "",
				entidadId: "",
				entidadRoleId: "",
				privs: [],
			},
			filters: {
				nomenclatura: "",
				detalle: "",
				activo: "",
			},
			filterKey: [],
			selectedHeaders: [],
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1
					? "Registrar Rol"
					: this.vista == false
					? "Editar Rol " + this.roleTitle
					: "Rol " + this.roleTitle;
			},
			filteredData() {
				return this.$filteredData().data !== undefined
					? this.$filteredData().data
					: this.$filteredData();
			},
			showHeaders() {
				return this.headers.filter((s) => this.selectedHeaders.includes(s));
			},
		},

		watch: {
			dialog(val) {
				if (val) {
					if (!this.$can(["admin_general"])) {
						this.editedItem.entidadId = this.$store.state.user.entidades;
					}
				} else {
					this.$close();
				}
			},
		},

		created() {
			this.$initialize();

			if (this.$can(["admin_general"])) {
				this.$getData(["clients", "entidades"]);
				//this.$store.state.user.username
				//console.log(this.$store.state.user.entidades);
			} else {
				this.$getData(["clients"]);
			}

			this.selectedHeaders = this.headers;
		},

		methods: {
			deleteItemEntidadRoles(item) {
				let id = item.id;
				let nombre = item.roleName;
				let vm = this;
				vm.getDataByRole(item);
				let titulo = vm.title;
				if (titulo.substring(titulo.length - 1) == "s")
					titulo = titulo.substring(0, titulo.length - 1);

				vm.$confirm({
					message: "¿Desea ELIMINAR el " + titulo + " " + nombre + "?",
					button: {
						no: "Cancelar",
						yes: "Eliminar",
					},

					callback: async function (confirm) {
						if (confirm) {
							Swal.alertGetInfo("Eliminando Registro");

							await vm.$axiosApi
								.delete(vm.route, id)
								.then((r) => {
									if (vm.editedItem.entidadRoleId) {
										vm.$axiosApi
											.delete(vm.routeEntidadRoles, vm.editedItem.entidadRoleId)
											.then((respDelete) => {
												vm.$respuesta(vm, respDelete, 0);
											})
											.catch((e) => {
												console.log(e);
												vm.$respuesta(vm, e, 0, true);
											});
									} else {
										vm.$respuesta(vm, r, 0);
									}
								})
								.catch((e) => {
									console.log(e);
									vm.$respuesta(vm, e, 0, true);
								});
							Swal.close();
						}
					},
				});
			},

			getRole(item) {
				//console.log(item);
				this.getPermisos(item.clientName, 1);
				this.editedIndex = item.id;
				this.editedItem = Object.assign({}, item);
				//let pos = item.roleName.indexOf("-");
				//let name = item.roleName.substring(0, Number(pos) - 1);
				this.editedItem.roleName = item.roleName;
				this.roleTitle = item.roleName;
				//this.getRolePermisos(item);
				this.getDataByRole(item);

				//this.editedItem.containerId.id = item.clientId;
				this.dialog = true;
			},
			/*async getPermisos(id) {
						      Swal.alertGetInfo("Obteniendo información <br><b></b>");
						      this.permissionsList = [];
						      this.editedItem.privs = [];
						      if (id) {
						        await this.$axiosApi
						          .getById(this.routePermissionsByClient, id)
						          .then((r) => {
						            if (r.data.data) {
						              this.permissionsList = r.data.data;
						              console.log(this.permissionsList);
						            }
						          })
						          .catch(function (error) {
						            console.log(error);
						          });
						      }
						    },*/
			async getPermisos(id, isName = false) {
				Swal.alertGetInfo("Obteniendo Permisos <br><b></b>");
				this.permissionsList = [];
				this.editedItem.privs = [];
				//console.log(id);
				if (id) {
					//console.log(this.routePermissionsByRole);
					let url = id + (isName ? "/" + isName : "");
					//console.log(url);
					await this.$axiosApi
						.getByCriteria(this.routePermissionsByRole, url)
						.then((r) => {
							Swal.close();
							if (r.data.data) {
								this.permissionsList = r.data.data;
								this.permissionsList = r.data.data.sort(function (a, b) {
									var nameA = a.name.toUpperCase(); // Convertir a mayúsculas para ordenar correctamente
									var nameB = b.name.toUpperCase(); // Convertir a mayúsculas para ordenar correctamente
									if (nameA < nameB) {
										return -1;
									}
									if (nameA > nameB) {
										return 1;
									}
									// Si los nombres son iguales
									return 0;
								});
								//console.log(this.permissionsList);
							}
						})
						.catch(function (error) {
							Swal.close();
							console.log(error);
						});
				}
				console.log(this.editedItem);
			},
			//en la edicion busco los permisos del rol
			async getRolePermisos(item) {
				this.editedItem.privs = [];
				//console.log(this.route);
				let id = item.name;
				if (id) {
					await this.$axiosApi
						.getById(this.route, id)
						.then((r) => {
							//Swal.close();
							//console.log(r.data.data);
							if (r.data.data) {
								this.editedItem.privs = r.data.data[0].privs;
								this.editedItem.clientId = r.data.data[0].clientId;
							}
						})
						.catch(function (error) {
							//Swal.close();
							console.log(error);
						});
				}
			},
			async getDataByRole(item) {
				//console.log(this.routeEntidadByRole);
				//console.log(item);
				this.editedItem.privs = [];
				this.editedItem.clientId = "";
				this.editedItem.entidadRoleId = "";
				this.editedItem.entidadId = "";
				let id = item.id;
				if (id) {
					await this.$axiosApi
						.getById(this.routeEntidadByRole, id)
						.then((r) => {
							if (r.data.data[0]) {
								this.editedItem.entidadId = Number(r.data.data[0].entidad_id);
								let dataRole = JSON.parse(r.data.data[0].role_id);
								this.editedItem.privs = dataRole[0].privs;
								this.editedItem.clientId = dataRole[0].clientId;
								this.editedItem.entidadRoleId = r.data.data[0].id;
							}
						})
						.catch(function (error) {
							//Swal.close();
							console.log(error);
						});
				}
			},
			filterByNomenclatura(item) {
				return this.$filterBy(item, "nomenclatura");
			},
			filterByDetalle(item) {
				return this.$filterBy(item, "detalle");
			},
			filterByActivo(item) {
				return this.$filterBy(item, "activo");
			},
		},
		mounted() {
			console.log("Componente " + this.title + " creado");
		},
	};
</script>
